import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import firebase from 'firebase/app';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject, config } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Config } from './../config/config';

import { IBroadcastMessage } from './../model/core/broadcast-message.interface';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { environment } from 'src/environments/environment';

declare var gapi: any;

@Injectable()
export class ApiService {
    private readonly broadcastMessageSubject: Subject<IBroadcastMessage> = new Subject<IBroadcastMessage>();

    headers: HttpHeaders | undefined;

    public apiError: any = null;
    public user: any = null;
    public auth: any = null;
    public gapiSetup: boolean = false;

    constructor(
        private readonly cookies: CookieService,
        private readonly router: Router,
        private readonly http: HttpClient
        ) {
    }
    async initGoogleAuth(): Promise<void> {
        const ctx = this;
        if (environment.production) {
            this.logConsole('initGoogleAuth');
            const pload = new Promise((resolve) => {
                this.logConsole('gapi load');
              gapi.load('auth2', resolve);
            });
        
            return pload.then(async () => {
                this.logConsole('gapi loaded');
                await gapi.auth2
                    .init({ client_id: environment.googleClientID })
                    .then((auth: any) => {
                        this.logConsole('auth init');
                        ctx.gapiSetup = true;
                        ctx.auth = auth;
                    });
            });
        }
    }
    login(): any {
        const ctx = this;
        if (environment.production) {
            ctx.auth.signIn().then((user: any) => {
                // update auth to user for access to additional functions (reloadAuthResponse)
                ctx.logConsole('auth', ctx.auth);
                ctx.user = user;
                const idToken = user.getAuthResponse().id_token;
                ctx.logConsole('login', user);
                ctx.logConsole('id_token', idToken);
                ctx.saveBearerToken(idToken);
                ctx.sendBroadcastMessage(Config.CastLoggedIn);
            });
        } else {
            ctx.logConsole('login');
            ctx.sendBroadcastMessage(Config.CastLoggedIn);
        }
    }
    logout(ctx: any): any {
        ctx.api.logConsole('logout');
        if (environment.production) {
            ctx.api.auth.signOut();
        }
        ctx.api.sendBroadcastMessage(Config.CastLoggedOut);
    }
    reloadAuth(ctx: any, castMessage: any, castParam: any): any {
        if (environment.production) {
            ctx.api.logConsole('reloadAuth', ctx);
            ctx.api.logConsole('auth', ctx.api.user);
            ctx.api.user.reloadAuthResponse().then((authResponse: any) => {
                const globalIdToken = authResponse.id_token;
                const globalExpiresIn = authResponse.expires_in;
                ctx.api.saveBearerToken(globalIdToken);
                ctx.api.logConsole('expires_in after refresh: ', globalExpiresIn);
                ctx.api.logConsole('auth token after refresh: ', globalIdToken);
                ctx.api.sendBroadcastMessage(castMessage, castParam);
            });
        } else {
            ctx.api.sendBroadcastMessage(castMessage, castParam);
        }
    }
    logConsole(message: string, variables?: any): void {
        if (Config.enableLogging) {
            if (variables == undefined) {
                console.log(message);
            } else {
                console.log(message, variables);
            }
        }
    }
    saveBearerToken(userToken: any): void {
        this.logConsole('save bearer token', userToken);
        const maxAge = 1*60*60 * 3 // expires after 1 hour ... changed to 3 hours for google token
        this.cookies.set('googleIdToken', userToken, maxAge, undefined, undefined, false, 'Strict');
    }
    clearTokens(): void {
        this.cookies.delete('googleIdToken');
    }
    getBearerToken(ctx: any): string {
        const token = `Bearer ${this.cookies.get('googleIdToken')}`; 
        // this will refresh the auth response for a new bearer token and keep the session refreshed
        return token;
    }
    setHeaders(): void {
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/json');
    }
    callAPILocal(actionName: string, requestData: any = {}): any {
        const actionUrl = `${Config.ApiUrlLocal}${actionName}`;
        this.apiError = null;
       return this.http.get(actionUrl)
            .pipe(
                catchError(this.handlePostError(actionName))
            );
    }
    callAPI(actionName: string, requestData: any = {}): Observable<IBroadcastMessage> {
        const body: any = JSON.stringify(requestData);
        const actionUrl = `${environment.apiUrlCloud}${actionName}`;
        this.apiError = null;
        let requestHeaders: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', this.getBearerToken(this));
        const options: any = {
            headers: requestHeaders,
            responseType: 'json',
            withCredentials: true
        };
       return this.http.post(actionUrl, body, options)
            .pipe(
                catchError(this.handlePostError(body))
            );
    }
    handlePostError(error: any): any {
        // fail silently
    }
    onApiError(error: any): any {
    }
    // broadcasting
    public sendBroadcastMessage(message: string, data: any = null): void {
        const broadcast: IBroadcastMessage = { message, data };
        this.setBroadcastMessage(broadcast);
    }
    public setBroadcastMessage(broadcast: IBroadcastMessage): void {
        this.broadcastMessageSubject.next(broadcast);
    }
    public listenForBroadcastMessage(): Observable<IBroadcastMessage> {
        return this.broadcastMessageSubject.asObservable();
    }
    navigateTo(page: string, itemId: number): void {
        if (itemId > 0) {
            this.router.navigate([page, itemId]);
        } else {
            this.router.navigate([page]);
        }
    }

    /* General Utilities */
    public getShortDate(fromDate: Date): string {
        return fromDate.toISOString().split('T')[0];
    }
    public findObjectInArrayByKey(checkObject: Array<any>, key: string, value: any): any {
        for (const check of checkObject) {
            if (check[key] === value) {
                return check;
            }
        }
        return null;
    }
    public isEmpty(checkObject: any): boolean {
        if (checkObject === undefined) {
            return true;
        }
        if (checkObject === null) {
            return true;
        }
        if (typeof checkObject === 'string') {
            if (checkObject === '') {
                return true;
            }
        }
        return false;
    }
}
