import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './../../service/api.service';

import { Config } from './../../config/config';
import { IBroadcastMessage } from 'src/app/model/core/broadcast-message.interface';

@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {


    constructor(
        private readonly router: Router,
        private zone: NgZone,
        public readonly api: ApiService) { 
    }
    public displayPostError = false;

    ngOnInit() {
        this.api.logConsole('login page', Config.buildVersion);
        this.api.initGoogleAuth();
        this.api.listenForBroadcastMessage().subscribe(broadcast => {
            this.onBroadcastReceived(broadcast);
        });
    }
    onBroadcastReceived(broadcast: IBroadcastMessage) {
      switch (broadcast.message) {
        case Config.CastLoggedIn:
            this.zone.run(() => {
                this.router.navigate(['/charts']);
            })
        break;
      }
    }
    logout(): void {
        this.api.logout(this);
    }
}
