import { Component, OnInit, ElementRef, ViewChild, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { ApiService } from './../../service/api.service';
import { GoogleChartService } from './../../service/google-chart.service';

import { Config } from './../../config/config';
import { Chiller } from './../../model/chiller';
import { Chart } from './../../model/chart';
import { ApiInput } from './../../model/api-input';
import { NormDate } from 'src/app/model/normalized/norm-date';
import { NormChiller } from 'src/app/model/normalized/norm-chiller';
import { NormChillerData } from 'src/app/model/normalized/norm-chiller-data';
import { environment } from 'src/environments/environment';
import { IBroadcastMessage } from 'src/app/model/core/broadcast-message.interface';


declare var Plotly: any;
@Component({
    selector: 'charts',
    templateUrl: 'charts.component.html',
})
export class ChartsComponent implements OnInit {
    @ViewChild('chart1_div') chart1!: ElementRef<HTMLElement>;
    @ViewChild('chart2_div') chart2!: ElementRef<HTMLElement>;
    @ViewChild('chart3_div') chart3!: ElementRef<HTMLElement>;
    @ViewChild('chart4_div') chart4!: ElementRef<HTMLElement>;
    @ViewChild('chart5_div') chart5!: ElementRef<HTMLElement>;
    @ViewChild('chart6_div') chart6!: ElementRef<HTMLElement>;
    @ViewChild('chart7_div') chart7!: ElementRef<HTMLElement>;

    @ViewChild('chart1_thumb') chart1Thumb!: ElementRef<HTMLElement>;
    @ViewChild('chart2_thumb') chart2Thumb!: ElementRef<HTMLElement>;
    @ViewChild('chart3_thumb') chart3Thumb!: ElementRef<HTMLElement>;
    @ViewChild('chart4_thumb') chart4Thumb!: ElementRef<HTMLElement>;
    @ViewChild('chart5_thumb') chart5Thumb!: ElementRef<HTMLElement>;
    @ViewChild('chart6_thumb') chart6Thumb!: ElementRef<HTMLElement>;

    private gLib: any;

    constructor(
        // public readonly auth: AngularFireAuth,
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly gChart: GoogleChartService,
        private readonly appRef: ApplicationRef,
        public readonly api: ApiService) { 
    }
    
    public chillers:Array<Chiller> = [];
    public chartInputs: ApiInput = {
        format: 'JSON',
        dateFrom: '2020-01-01',
        dateTo: '2020-01-01',
        chillers: new Array<Chiller>()
    }
    public dateFrom: Date = new Date();
    public dateTo: Date = new Date();
    public selectedChillerCount = 1;
    public displayCharts = false;
    public displayVariables = false;
    public googleChartsAvailable = false;
    public displayLink = '';
    public chartData: any;
    public normalized: Array<NormChiller> = new Array<NormChiller>();
    public chartViews: Array<Array<boolean>> = new Array<Array<boolean>>();
    public displayChartSelectors = false;
    public displayMissingData = false;
    public displayLoading = false;
    public displayApiError = false;
    public display3DChart = false;
    public displayPostError = true;
    public displayCooling = true;
    public displayCOP = true;
    public displayTab = 'general';
    public googleSheetIsCurrent = false;
    public rows3d: Array<Array<number>> = [];
    public totRowd3d = 0;
    public temps3d: Array<number> = [];
    public evaps3d: Array<number> = [];
    public percs3d: Array<number> = [];
    public selectedEvap: number = 0;
    public selected3dUnit: string | undefined;
    public filteredCooling: boolean = false;

    public globalChartRef1: any;
    public globalChartRef2: any;
    public globalChartRef3: any;
    public globalChartRef4: any;
    public globalChartRef5: any;
    public globalChartRef6: any;

    ngOnInit() {
        this.api.logConsole('charts ngOnInit');
        this.api.listenForBroadcastMessage().subscribe(broadcast => {
            this.onBroadcastReceived(broadcast);
        });
        this.initReportDates();
        this.initCharts();
        this.getChillers();
    }
    onBroadcastReceived(broadcast: IBroadcastMessage) {
        switch (broadcast.message) {
            case Config.CastGetChillers:
                break;
            case Config.CastGetChillerData:
                this.refreshedChillerData(this);
                break;
            case Config.CastGet3dData:
                this.refreshedChiller3d(this, broadcast.data);
                break;
            case Config.CastLoggedOut:
                this.api.navigateTo('/', 0);
                break;
        }
    }
    initReportDates(): void {
        this.api.logConsole('init report dates');
        let today = new Date();
        this.dateFrom.setDate(today.getDate() - 1);
        this.dateTo.setDate(today.getDate());
    }

    // API communication
    getChillers(): void {
        this.api.logConsole('get chillers');
        this.displayLoading = true;
        this.displayPostError = false;
        if (environment.production) {
            this.api.callAPI(Config.GetChillersActionCloud)
                .subscribe(
                    (response: any) => this.onGetChillers(response),
                    (error: any) => {
                        this.api.onApiError(error);
                        this.displayPostError = true;
                        this.displayLoading = false;
                    }
                );
        } else {
            this.api.callAPILocal(Config.GetChillersActionLocal)
                .subscribe(
                    (response: any) => this.onGetChillers(response),
                    (error: any) => {
                        this.api.onApiError(error);
                        this.displayPostError = true;
                        this.displayLoading = false;
                        this.displayVariables = false;
                    }
                );
        }
    }
    onGetChillers(data: any): void {
        this.displayLoading = false;
        this.displayVariables = true;
        if (data.data != {}) {
            this.api.logConsole('got chillers', data);
            Object.assign(this.chillers, data.data.chillers);
            for (let i = 0; i < this.chillers.length; i++) {
                let chiller = this.chillers[i];
                chiller.name = chiller.id;
                chiller.dropOffPercent = 0.01;
                chiller.operationYears = 2;
                chiller.selected = (i == 0);
            }
            for (let j = 0; j < Config.availableCharts; j++) {
                this.chartViews[j] = new Array<boolean>();
                this.chartViews[j].push(true);
                for (let i = 0; i < this.chillers.length; i++) {
                    this.chartViews[j].push(false);
                }
            }
            this.api.logConsole('this.chartViews', this.chartViews);
            this.appRef.tick();
        } else {
            this.displayApiError = true;
        }
    }
    getChillerData(): void {
        this.displayPostError = false;
        this.displayCharts = false;
        this.displayMissingData = false;
        this.displayApiError = false;
        this.displayLoading = true;
        this.displayCOP = true;
        this.displayCooling = true;
        this.chartData = [];
        this.collectToggledChillers();
        this.toggleDataVariables();
        this.api.logConsole('fetch chart data', this.chartInputs);
        this.displayChartSelectors = (this.chartInputs.chillers.length > 1);
        this.googleSheetIsCurrent = true;
        this.api.reloadAuth(this, Config.CastGetChillerData, null);
    }
    refreshedChillerData(ctx: any) {
        if (environment.production) {
            ctx.api.callAPI(Config.GetChillerDataActionCloud, ctx.chartInputs)
                .subscribe(
                    (response: any) => ctx.onGetChillerData(response),
                    (error: any) => {
                        ctx.api.onApiError(error);
                        ctx.displayPostError = true;
                        ctx.displayLoading = false;
                        ctx.displayVariables = true;
                        this.appRef.tick();
                    }
                );
        } else {
            ctx.api.callAPILocal(Config.GetChillerDataActionLocal, ctx.chartInputs)
                .subscribe(
                    (response: any) => ctx.onGetChillerData(response),
                    (error: any) => {
                        ctx.api.onApiError(error);
                        ctx.displayPostError = true;
                        ctx.displayLoading = false;
                        ctx.displayVariables = true;
                        this.appRef.tick();
                    }
                );
        }
    }
    onGetChillerData(data: any): void {
        this.displayLoading = false;
        this.chartData = [];
        if (data.data != {}) {
            this.api.logConsole('got chiller data');
            Object.assign(this.chartData, data.data.chillers);
            this.api.logConsole(this.chartData);
            this.displayLink = data.data.file_url;
            this.drawCharts();
            this.appRef.tick();
        } else {
            this.displayApiError = true;
            this.appRef.tick();
        }
    }
    loadChiller3d(selectObject: any): void {
        const chillerName = selectObject.target.value;
        if (chillerName != '0') {
            this.displayPostError = false;
            this.selected3dUnit = chillerName;
            this.displayLoading = true;
            const chiller = this.api.findObjectInArrayByKey(this.chillers, 'name', chillerName);
            this.api.logConsole('load 3d data', chiller);
            this.api.reloadAuth(this, Config.CastGet3dData, chiller);
        }
    }
    refreshedChiller3d(ctx: any, chiller: any) {
        if (environment.production) {
            ctx.api.callAPI(Config.Get3dDataActionCloud, chiller)
                .subscribe(
                    (response: any) => ctx.onGet3dData(response),
                    (error: any) => {
                        ctx.api.onApiError(error);
                        ctx.displayPostError = true;
                        ctx.displayLoading = false;
                        this.appRef.tick();
                    }
                );
        } else {
            ctx.api.callAPILocal(Config.Get3dDataActionLocal, chiller)
                .subscribe(
                    (response: any) => ctx.onGet3dData(response),
                    (error: any) => {
                        ctx.api.onApiError(error);
                        ctx.displayPostError = true;
                        ctx.displayLoading = false;
                        this.appRef.tick();
                    }
                );
        }
    }
    onGet3dData(data: any): void {
        Object.assign(this.rows3d, data.data['3d_data']);
        for (let i = 1; i < this.rows3d.length; i++) { // skip first element, titles
            let row = this.rows3d[i];
            if (this.temps3d.indexOf(Number(row[0])) < 0) {
                this.temps3d.push(Number(row[0]));
            }
            if (this.evaps3d.indexOf(Number(row[1])) < 0) {
                this.evaps3d.push(Number(row[1]));
            }
            if (this.percs3d.indexOf(Number(row[4])) < 0) {
                this.percs3d.push(Number(row[4]));
            }
        }
        this.selectEvapTemp(this.evaps3d[0]);
        this.appRef.tick();
    }

    // form control changes
    setFromDate(event: any): void {
        this.dateFrom = new Date(event.target.value);
        this.chartInputs.dateFrom = this.api.getShortDate(this.dateFrom);
        this.googleSheetIsCurrent = false;
    }
    setToDate(event: any): void {
        this.dateTo = new Date(event.target.value);
        this.chartInputs.dateTo = this.api.getShortDate(this.dateTo);
        this.googleSheetIsCurrent = false;
    }
    toggleChiller(chiller: Chiller): void {
        chiller.selected = !chiller.selected;
        this.updateChillerCount();
        this.googleSheetIsCurrent = false;
    }
    toggleDataVariables(): void {
        this.displayVariables = !this.displayVariables;
        this.appRef.tick();
    }
    updateChillerCount(): void {
        let selected: number = 0;
        for(let i = 0; i < this.chillers.length; i++) {
            if (this.chillers[i].selected) {
                selected++;
            }
        }
        this.selectedChillerCount = selected;
        if (selected > 1) {
            this.chartInputs.format = 'JSON';
        }
    }
    collectToggledChillers(): void {
        let selected:Array<any> = [];
        for(let i = 0; i < this.chillers.length; i++) {
            if (this.chillers[i].selected) {
                let chillerData = { 
                    id: this.chillers[i].name,
                    name: this.chillers[i].name,
                    dropOffPercent: this.chillers[i].dropOffPercent,
                    operationYears: this.chillers[i].operationYears
                }
                selected.push(chillerData);
            }
        }
        this.chartInputs.chillers = selected;
    }
    setDropPercent(chiller: Chiller, event: any): void {
        chiller.dropOffPercent = Number(event.target.value);
        this.googleSheetIsCurrent = false;
    }
    setOperationYears(chiller: Chiller, event: any): void {
        chiller.operationYears = Number(event.target.value);
        this.googleSheetIsCurrent = false;
    }
    logout() {
        this.api.logout(this);
    }


    // charting
    initCharts(): void {
        this.api.logConsole('init google charts');
        this.gLib = this.gChart.getGoogle();
        this.gLib.charts.load('current', {'packages':['corechart','table']});
        this.gLib.charts.setOnLoadCallback(this.chartsAvailable.bind(this));
    }
    chartsAvailable(): void {
        this.googleChartsAvailable = true;
    }
    drawCharts(): void {
        this.api.logConsole('draw charts');
        if (this.googleChartsAvailable) {
            if (Object.keys(this.chartData[0].data['Time']).length > 0) {
                this.displayCharts = true;
                this.displayCOP = true;
                this.displayCooling = false;
                this.drawChart1();
                this.drawChart2();
                this.drawChart3();
                this.drawChart4();
                this.drawChart5();
                this.drawChart6();
            } else {
                this.displayMissingData = true;
                this.displayVariables = true;
            }
        }
    }
    drawChart1(): void {
        this.api.logConsole('draw chart 1');
        const chartIdx = 0;

        let data = new this.gLib.visualization.DataTable();
        data.addColumn('datetime', 'Time');

        let chartTitle = 'COP VS Manufacturer COP: ';
        const chillerCount = this.chartInputs.chillers.length;
        let displayCount = 0;
        for (let i = 0; i < chillerCount; i++) {
            let chillerName = this.chartInputs.chillers[i].name;
            if (this.displayUnit(chartIdx, i)) {
                data.addColumn('number', `${chillerName} COP`);
                data.addColumn('number', `${chillerName} COP manufacturer Y0`);
                data.addColumn('number', `${chillerName} COP manufacturer Yn`);
                chartTitle += (displayCount++ > 0 ? ', ': '') + chillerName;
            }
        }

        let chartRows = new Array();
        const rowCount = Object.keys(this.chartData[0].data['Time']).length;
        for (let i = 0; i < rowCount; i++) {
            let cellDate = new Date(this.chartData[0].data['Time'][i]);
            let rowData = new Array();
            rowData.push(cellDate);
            for (let j = 0; j < this.chartInputs.chillers.length; j++) {
                if (this.displayUnit(chartIdx, j)) {
                    let chillerName = this.chartInputs.chillers[j].id;
                    let chartRow = this.chartData[j].data;
                    rowData.push(chartRow[`${chillerName} COP`][i]);
                    rowData.push(chartRow[`${chillerName} COP manufacturer Y0`][i]);
                    rowData.push(chartRow[`${chillerName} COP manufacturer Yn`][i]);
                }
            }
            chartRows.push(rowData);
        }
        data.addRows(chartRows);

        const options = this.getGoogleChartOptions(chartTitle, 'COP', false, true, 'date', false);

        let chart = new this.gLib.visualization.LineChart(this.chart1?.nativeElement);
        this.addChartThumbnail(this.chart1Thumb?.nativeElement, chart);
        chart.draw(data, options);
    }
    drawChart2(): void {
        this.api.logConsole('draw chart 2');
        const chartIdx = 1;

        let temperatures = new Array<number>();
        const rowCount = Object.keys(this.chartData[0].data['Time']).length;
        for (let i = 0; i < rowCount; i++) {                
            for (let j = 0; j < this.chartInputs.chillers.length; j++) {
                if (this.displayUnit(chartIdx, j)) {
                    let chartRow = this.chartData[j].data;
                    let roundedAmbient = Math.round(chartRow['Ambient temperature'][i]);
                    if (!isNaN(roundedAmbient)) {
                        if (temperatures.indexOf(roundedAmbient) < 0) {
                            temperatures.push(roundedAmbient);
                        }
                    }
                }
            }

        }
        temperatures.sort();
        
        let chartTitle = 'Chillers : ';
        const chillerCount = this.chartInputs.chillers.length;
        let displayCount = 0;
        for (let i = 0; i < chillerCount; i++) {
            let chillerName = this.chartInputs.chillers[i].name;
            if (this.displayUnit(chartIdx, i)) {
                chartTitle += (displayCount++ > 0 ? ', ': '') + chillerName;
            }
        }
        
        let data = new this.gLib.visualization.DataTable();
        data.addColumn('number', 'Load Percentage');
        for (let i = 0; i < temperatures.length; i++) {
            data.addColumn('number', temperatures[i] + ' C');
        }

        let chartRows = new Array();
        for (let i = 0; i < rowCount; i++) {
            let cellDate = new Date(this.chartData[0].data['Time'][i]);
            let rowData = new Array();
            rowData.push(cellDate);
            for (let j = 0; j < this.chartInputs.chillers.length; j++) {
                if (this.displayUnit(chartIdx, j)) {
                    let chillerName = this.chartInputs.chillers[j].id;
                    let chartRow = this.chartData[j].data;
                    let roundedAmbient = Math.round(chartRow['Ambient temperature'][i]);
                    if (!isNaN(roundedAmbient)) {
                        let column = temperatures.indexOf(roundedAmbient);
                        let rowData = new Array<any>();
                        rowData.push(chartRow[`${chillerName} load percentage`][i] / 100);
                        for (let j = 0; j < temperatures.length; j++) {
                            rowData.push(
                                column == j ? chartRow[`${chillerName} COP`][i] : null
                            );
                        }
                        chartRows.push(rowData);
                    }
                }
            }
        }
        data.addRows(chartRows);

        const options = this.getGoogleChartOptions(chartTitle, 'COP', true, true, 'percent', true);

        let chart = new this.gLib.visualization.ScatterChart(this.chart2?.nativeElement);
        this.addChartThumbnail(this.chart2Thumb?.nativeElement, chart);
        chart.draw(data, options);
    }
    drawChart3(): void {
        this.api.logConsole('draw chart 3');
        const chartIdx = 2;

        let temperatures = new Array<number>();
        const rowCount = Object.keys(this.chartData[0].data['Time']).length;
        for (let i = 0; i < rowCount; i++) {                
            for (let j = 0; j < this.chartInputs.chillers.length; j++) {
                if (this.displayUnit(chartIdx, j)) {
                    let chartRow = this.chartData[j].data;
                    let roundedAmbient = Math.round(chartRow['Ambient temperature'][i]);
                    if (!isNaN(roundedAmbient)) {
                        if (temperatures.indexOf(roundedAmbient) < 0) {
                            temperatures.push(roundedAmbient);
                        }
                    }
                }
            }

        }
        temperatures.sort();
        
        let chartTitle = 'Chillers : ';
        const chillerCount = this.chartInputs.chillers.length;
        let displayCount = 0;
        for (let i = 0; i < chillerCount; i++) {
            let chillerName = this.chartInputs.chillers[i].name;
            if (this.displayUnit(chartIdx, i)) {
                chartTitle += (displayCount++ > 0 ? ', ': '') + chillerName;
            }
        }
        
        let data = new this.gLib.visualization.DataTable();
        data.addColumn('number', 'Load Percentage');
        for (let i = 0; i < temperatures.length; i++) {
            data.addColumn('number', temperatures[i] + ' C');
        }

        let chartRows = new Array();
        for (let i = 0; i < rowCount; i++) {
            let cellDate = new Date(this.chartData[0].data['Time'][i]);
            let rowData = new Array();
            rowData.push(cellDate);
            for (let j = 0; j < this.chartInputs.chillers.length; j++) {
                if (this.displayUnit(chartIdx, j)) {
                    let chillerName = this.chartInputs.chillers[j].id;
                    let chartRow = this.chartData[j].data;
                    let roundedAmbient = Math.round(chartRow['Ambient temperature'][i]);
                    let column = temperatures.indexOf(roundedAmbient);
                    if (!isNaN(roundedAmbient)) {
                        let rowData = new Array<any>();
                        rowData.push(chartRow[`${chillerName} load percentage`][i] / 100);
                        for (let j = 0; j < temperatures.length; j++) {
                            rowData.push(
                                column == j ? chartRow[`${chillerName} COP manufacturer Yn`][i] : null
                            );
                        }
                        chartRows.push(rowData);
                    }
                }
            }
        }
        data.addRows(chartRows);

        const options = this.getGoogleChartOptions(chartTitle, 'COP Manufacturer', true, true, 'percent', true);            

        let chart = new this.gLib.visualization.ScatterChart(this.chart3?.nativeElement);
        this.addChartThumbnail(this.chart3Thumb?.nativeElement, chart);
        chart.draw(data, options);
    }
    drawChart4(): void {
        this.api.logConsole('draw chart 4');
        const chartIdx = 3;

        // collect all reported dates
        let chartDates = new Array<Date>();
        for (let i = 0; i < this.chartData.length; i++) { // for each dataset
            let dataSet = this.chartData[i].data;
            let dsRows = Object.keys(dataSet['Time']).length;
            for (let j = 0; j < dsRows; j++) {
                let shortDate = dataSet['Time'][j].toString().split(' ')[0];
                if (chartDates.indexOf(shortDate) < 0) {
                    chartDates.push(shortDate);
                }
            }
        }
        chartDates.sort();
        
        // setup static kW line
        const availableChillers = this.availableChillers();
        let combChillers = 617;
        let combChillers2 = 0;
        let staticLineTitle2 = '';
        if (availableChillers.length > 1 && this.chartViews[3][0]) { // all view
            combChillers2 = 617 * 2;
            staticLineTitle2 = `2 Chillers: 2 * 617 kW`;
        }
        if (!this.chartViews[3][0]) {
            for (let i = 1; i < this.chartViews[3].length; i++) {
                if (this.chartViews[3][i]) {
                    let tmpSize = this.chillers[i - 1].size;
                    if (tmpSize != undefined) {
                        combChillers = tmpSize;
                    }
                }
            }
        }
        let staticLineTitle = `1 Chiller: ${combChillers} kW`;

        // setup columns
        const chartTitle = 'Daily Cooling Load';
        let data = new this.gLib.visualization.DataTable();
        data.addColumn('datetime', 'Time');
        for (let i = 0; i < chartDates.length; i++) {
            data.addColumn('number', chartDates[i]);
            data.addColumn({type: 'string', role: 'tooltip', p: {'html': true}});
        }
        data.addColumn('number', staticLineTitle);
        data.addColumn({type: 'string', role: 'tooltip', p: {'html': true}});
        if (combChillers2 != 0) {
            data.addColumn('number', staticLineTitle2);
            data.addColumn({type: 'string', role: 'tooltip', p: {'html': true}});
        }

        // initialize data arrays
        let rows = new Array();
        for (let j = 0; j < 96; j++) {
            let cellTime = new Date(2020, 0, 1); // use same date for date time alignment
            cellTime.setHours(0, j * 15);
            let rowData = new Array<any>((chartDates.length * 2) + 3 + (combChillers2 == 0 ? 0 : 2)); // date and tooltip, + time + static line
            rowData[0] = cellTime;
            const formattedTime = this.formatTime(cellTime);
            for (let i = 0; i < chartDates.length; i++) {
                rowData[(i * 2) + 1] = 0; // default 0 value
                rowData[(i * 2) + 2] = `${formattedTime}: 0 kW`; // formatted tooltip default
            }
            if (combChillers2 == 0) {
                rowData[rowData.length - 2] = combChillers;
                rowData[rowData.length - 1] = this.formatTimeToolTip('', formattedTime, combChillers);
            } else {
                rowData[rowData.length - 4] = combChillers;
                rowData[rowData.length - 3] = this.formatTimeToolTip('', formattedTime, combChillers);
                rowData[rowData.length - 2] = combChillers2;
                rowData[rowData.length - 1] = this.formatTimeToolTip('', formattedTime, combChillers2);
            }
            rows.push(rowData);
        }
        
        // fill arrays
        for (let k = 0; k < availableChillers.length; k++) {
            if (this.chartViews[3][k + 1] || this.chartViews[3][0]) { // total chiller data for single or all
                let chillerDataSet = this.chartData[k];
                let chartName = chillerDataSet.id;
                let dataSet = chillerDataSet.data;
                let dsRows = Object.keys(chillerDataSet.data['Time']).length;
                for (let i = 0; i < dsRows; i++) {
                    let timeStamp = dataSet['Time'][i];
                    let tsParts = timeStamp.split(' ');
                    let tsDate = tsParts[0];
                    let tsTime = tsParts[1];
                    let tsDateOffset = chartDates.indexOf(tsDate);
                    let tsTimeOffset = this.getTimeOffset(tsTime);
                    let cellTime = new Date(2020, 1, 1); // use same date for date time alignment
                    cellTime.setHours(0, tsTimeOffset * 15);
                    const formattedTime = this.formatTime(cellTime);
                    let chillerData = dataSet[`${chartName} cooling load kW (m*cp*dT)`][i];
                    let aggregateData = rows[tsTimeOffset][(tsDateOffset * 2) + 1] + chillerData;
                    rows[tsTimeOffset][(tsDateOffset * 2) + 1] = aggregateData;
                    let toolTip = this.formatTimeToolTip(tsDate, formattedTime, aggregateData);
                    rows[tsTimeOffset][(tsDateOffset * 2) + 2] = toolTip;
                }
            }
        }
        data.addRows(rows);

        const options = this.getGoogleChartOptions(chartTitle, 'Cooling Load (kW)', false, true, 'time', false);

        let chart = new this.gLib.visualization.LineChart(this.chart4?.nativeElement);
        this.addChartThumbnail(this.chart4Thumb?.nativeElement, chart);

        this.filteredCooling = false;
        let ctx = this;
        this.gLib.visualization.events.removeListener(chart, 'select');
        this.gLib.visualization.events.addListener(chart, 'select', function() {
            let sel = chart.getSelection();
            if (sel.length > 0 && !ctx.filteredCooling) {
                if (sel[0].row == null) {
                    let col = sel[0].column;
                    let view = new ctx.gLib.visualization.DataView(data);
                    const columnCount = view.getNumberOfColumns();
                    for (let i = 0; i < columnCount; i++) {
                        if (i == col) {
                            var staticLine = columnCount - 2;
                            if (combChillers2 > 0) {
                                view.setColumns([0, col, col + 1, staticLine - 2, staticLine - 1, staticLine, staticLine + 1]);
                            } else {
                                view.setColumns([0, col, col + 1, staticLine, staticLine + 1]);
                            }
                            ctx.filteredCooling = true;
                        }
                    }
                    chart.draw(view, options);
                }
            }
        });
        chart.draw(data, options);
    }
    drawChart5(): void {
        this.api.logConsole('draw chart 5');
        const chartIdx = 4;

        const designFlow = 27;
        let data = new this.gLib.visualization.DataTable();
        data.addColumn('datetime', 'Time');
        data.addColumn('number', `designed flow= ${designFlow} l/s`);

        let chartTitle = 'Flowrate l/s : ';
        const chillerCount = this.chartInputs.chillers.length;
        let displayCount = 0;
        for (let i = 0; i < chillerCount; i++) {
            let chillerName = this.chartInputs.chillers[i].name;
            if (this.displayUnit(chartIdx, i)) {
                data.addColumn('number', `${chillerName} OPtimal Flowrate l/s`);
                data.addColumn('number', `${chillerName} Flowrate l/s`);
                chartTitle += (displayCount++ > 0 ? ', ': '') + chillerName;
            }
        }

        let chartRows = new Array();
        const rowCount = Object.keys(this.chartData[0].data['Time']).length;
        for (let i = 0; i < rowCount; i++) {
            let cellDate = new Date(this.chartData[0].data['Time'][i]);
            let rowData = new Array();
            rowData.push(cellDate);
            rowData.push(designFlow);
            for (let j = 0; j < this.chartInputs.chillers.length; j++) {
                if (this.displayUnit(chartIdx, j)) {
                    let chillerName = this.chartInputs.chillers[j].id;
                    let chartRow = this.chartData[j].data;
                    rowData.push(chartRow[`${chillerName} OPtimal Flowrate l/s`][i]);
                    rowData.push(chartRow[`${chillerName} Flowrate l/s`][i]);
                }
            }
            chartRows.push(rowData);
        }
        data.addRows(chartRows);
        
        const options = this.getGoogleChartOptions(chartTitle, 'Flow rate l/s', false, true, 'date', false);

        let chart = new this.gLib.visualization.LineChart(this.chart5?.nativeElement);
        this.addChartThumbnail(this.chart5Thumb?.nativeElement, chart);
        chart.draw(data, options);
    }
    drawChart6(): void {
        this.api.logConsole('draw chart 6');
        const chartIdx = 5;

        let data = new this.gLib.visualization.DataTable();
        data.addColumn('datetime', 'Time');

        let chartTitle = 'Electricity saving kWh : ';
        const chillerCount = this.chartInputs.chillers.length;
        let displayCount = 0;
        for (let i = 0; i < chillerCount; i++) {
            let chillerName = this.chartInputs.chillers[i].name;
            if (this.displayUnit(chartIdx, i)) {
                data.addColumn('number', `${chillerName} Electricity saving kWh`);
                chartTitle += (displayCount++ > 0 ? ', ': '') + chillerName;
            }
        }

        let chartRows = new Array();
        const rowCount = Object.keys(this.chartData[0].data['Time']).length;
        for (let i = 0; i < rowCount; i++) {
            let cellDate = new Date(this.chartData[0].data['Time'][i]);
            let rowData = new Array();
            rowData.push(cellDate);
            for (let j = 0; j < this.chartInputs.chillers.length; j++) {
                if (this.displayUnit(chartIdx, j)) {
                    let chillerName = this.chartInputs.chillers[j].id;
                    let chartRow = this.chartData[j].data;
                    rowData.push(chartRow[`${chillerName} Electricity saving kWh`][i]);
                }
            }
            chartRows.push(rowData);
        }
        data.addRows(chartRows);

        const options = this.getGoogleChartOptions(chartTitle, 'Electricity saving kWh', false, true, 'date', false);

        let chart = new this.gLib.visualization.LineChart(this.chart6?.nativeElement);
        this.addChartThumbnail(this.chart6Thumb?.nativeElement, chart);
        chart.draw(data, options);
    }
    getData(): any {
        let arr = [];
        for(let i=0;i<10;i++) {
            arr.push(Array(10).fill(i).map(() => Math.random()))
        }
        return arr;
    }
    formatTime(rawDate: Date): string {
        const hr = rawDate.getHours();
        const min = rawDate.getMinutes();
        let formatted = (hr < 10 ? '0' : '') + hr.toString();
        formatted += (min < 10 ? ':0' : ':') + min.toString();
        formatted += (hr > 12 ? 'pm' : 'am');
        return formatted;
    }
    formatTimeToolTip(date: string, formattedTime: string, load: number): string {
        let formattedDate = date;
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if (date != '') {
            const dateParts = date.split('-');
            formattedDate = `${monthNames[Number(dateParts[1]) - 1]} ${dateParts[2]}, ${dateParts[0]}`;
        }
        const formattedDateTime = date == '' ? formattedTime : `${formattedDate}, ${formattedTime}`;
        const formattedLoad = `Cooling Load: <b>${load} kW</b>`;
        const formatted = `<b>${formattedDateTime}</b><br>${formattedLoad}`;
        return formatted;
    }
    getTimeOffset(time: string): number {
        let offset = 0;
        const timeParts = time.split(':');
        offset = (Number(timeParts[0]) * 4) + (Number(timeParts[1]) / 15 * 1);
        return offset;
    }
    toggleAnalysis(): void {
        this.displayCOP = !this.displayCOP;
        this.displayCooling = !this.displayCOP;
        this.appRef.tick();
    }
    getTimeRange(): any {
        let range = {
            min: '9999-12-31 23:59:59',
            max: '0000-01-01 00:00:00',
            minD: new Date(),
            maxD: new Date(),
            offsets: 0
        }
        return range;
    }

    // 3D chart
    draw3dChart(): void {
        this.api.logConsole('draw 3d chart');
        let chartRows = [];
        for (let i = 0; i < this.temps3d.length; i++) {
            chartRows.push(new Array(this.percs3d.length));
        }
        for (let i = 0; i < this.rows3d.length; i++) {
            let row = this.rows3d[i];
            if (row[1] == this.selectedEvap) {
                let rowPercOff = this.percs3d.indexOf(Number(row[4]));
                let rowTempOff = this.temps3d.indexOf(Number(row[0]));
                chartRows[rowTempOff][rowPercOff] = Number(row[3]);
            }
        }
        const hovertemplate = 'Cooling load [%]: %{x}<br>Inlet Condenser Temperature (C°): %{y}<br>Manufacturer COP: %{z}<extra></extra>';
        let data_z1 = {z: chartRows, type: 'surface', hovertemplate: hovertemplate};
        
        const layout = {
            title: 'Manufacturer COP<br\><i>(color bar is manufacturer COP)</i>',
            autosize: false,
            width: 1200,
            height: 1000,
            margin: {
                l: 65,
                r: 50,
                b: 65,
                t: 90,
            },
            scene: {
                aspectratio: {
                    x: 1, y: 1, z: 1
                },
                xaxis: {
                    title: 'Cooling load [%]',
                    nticks: this.percs3d.length,
                    ticktext: this.percs3d,
                    tickvals: this.getArrayTickVals(this.percs3d)
                },
                yaxis: {
                    title: 'Inlet Condenser Temperature (C°)',
                    nticks: 10,
                    ticktext: this.getArrayTickTexts(this.temps3d),
                    tickvals: this.getArrayTickVals(this.temps3d),
                    tickfont: {
                        size: 12
                    },
                },
                zaxis:{
                    title: 'Manufacturer COP'
                }
            }
        };
        
        const options = {
            displaylogo: false,
            scrollZoom: false,
            editable: false,
            displayModeBar: true
        };
        Plotly.newPlot(this.chart7?.nativeElement, [data_z1], layout, options);
        this.display3DChart = true;
        this.displayLoading = false;
        this.appRef.tick();
    }
    getArrayTickTexts(arrElems: Array<number>): Array<string> {
        let tickTexts: Array<string> = [];
        for (let i = 0; i < arrElems.length; i++) {
            let elemText = arrElems[i];
            if (elemText == Math.floor(elemText)) {
                tickTexts.push(elemText.toString());
            } else {
                tickTexts.push(' ');
            }
        }
        return tickTexts;
    }
    getArrayTickVals(arrElems: Array<number>): Array<number> {
        let tickVals: Array<number> = [];
        for (let i = 0; i < arrElems.length; i++) {
            tickVals.push(i);
        }
        return tickVals;
    }
    selectEvapTemp(select: number): void {
        this.selectedEvap = select;
        this.display3DChart = false;
        this.draw3dChart();
    }

    // charting helpers
    displayUnit(chart: number, unitIdx: number): boolean {
        return (this.chartViews[chart][0] || this.chartViews[chart][unitIdx + 1]);
    }
    getGoogleChartOptions(chartTitle: string, yAxisTitle: string, 
        allowYZoom: boolean, isSideLegend: boolean, hAxisType: string, showPoints: boolean): any {
        let hAxisConf = null;
        let toolTipConf = {};
        switch (hAxisType) {
            case 'percent':
                hAxisConf = {
                    title: 'Load Percentage',
                    format: '#.#',
                    minValue: 0,
                    maxValue: 1,
                    titleTextStyle: {
                        fontSize: 14,
                        italic: false
                    }
                }
                break;
            case 'time':
                hAxisConf = {
                    title: 'Time of day',
                    format: 'HH:mm',
                    titleTextStyle: {
                        fontSize: 14,
                        italic: false
                    }
                }
                toolTipConf = { isHtml: true };
                break;
            default: // date
                hAxisConf = {
                    format: 'yy-MM-dd hh:mm a',
                    slantedText: true,
                    slantedTextAngle: 60,
                    textStyle: {
                        fontSize: 10
                    }
                }
                break;
        }
        return {
            title: chartTitle,
            titleTextStyle: { 
                fontSize: 14,
                bold: true
            },
            tooltip: toolTipConf,
            fontSize: 12,
            pointSize: (showPoints ? 3  : 0),
            legend: { 
                alignment: 'center',
                position: (isSideLegend ? 'right' : 'top')
            },
            vAxis: {
                title: yAxisTitle,
                titleTextStyle: {
                    fontSize: 14,
                    italic: false
                }
            },
            hAxis: hAxisConf,
            width: 1200,
            height: 500,
            explorer: { 
                axis: (allowYZoom ? 'both' : 'horizontal'),
                actions: ['dragToZoom', 'rightClickToReset'] 
            }
        };
    }
    addChartThumbnail(thumbRef: any, chart: any): void {
        this.gLib.visualization.events.addListener(chart, 'ready', function() {
            const dataImg = chart.getImageURI();
            thumbRef.innerHTML = `<img src="${dataImg}">`;
        });
    }
    toggleChartUnits(chartIdx: number, chillerIdx: number): void {
        for (let i = 0; i < this.chartViews[chartIdx].length; i++) {
            this.chartViews[chartIdx][i] = false;
        }
        this.chartViews[chartIdx][chillerIdx] = true;
        eval(`this.drawChart${chartIdx + 1}()`);
    }
    availableChillers(): Array<Chiller> {
        let available: Array<Chiller> = new Array<Chiller>();
        for(let i = 0; i < this.chillers.length; i++) {
            if (this.chillers[i].selected) {
                available.push(this.chillers[i]);
            }
        }
        return available;
    }
    toggleChartView(): void {
        this.displayTab = (this.displayTab == '3d' ? 'general' : '3d');
        this.appRef.tick();
    }
}
