import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './views/auth/login.component'
import { ChartsComponent } from './views/chart/charts.component'


const routes: Routes = [
  {
      path: 'charts',
      component: ChartsComponent
  },
  { 
    path: '**', 
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
