export class Config {
    // ng serve
    // ng build --prod --aot --output-Hashing=bundles
    public static availableCharts = 7;
    public static buildVersion = 1.87;
    public static enableLogging = false;

    public static HvacLoginActionCloud = 'hvaclogin';
    public static GetChillersActionCloud = 'getchillers';
    public static GetChillerDataActionCloud = 'getchillerdata';
    public static Get3dDataActionCloud = 'get3ddata';
    
    public static ApiUrlLocal = '/assets/';
    public static GetChillersActionLocal = 'GetChillers.json';
    public static GetChillerDataActionLocal = 'All_results.json';
    public static Get3dDataActionLocal = 'HVAC.json';

    public static FLDtime = 'Time';
    public static FLDcop = 'COP';
    public static FLDcopMfY0 = 'COP manufacturer Y0';
    public static FLDcopMfYn = 'COP manufacturer Yn';
    public static FLDchillWaterTemp = 'Chilled water temperature';
    public static FLDdeltCoolLoad = 'Delta Cooling Load kWh';
    public static FLDdeltElecCons = 'Delta Electricity consumption kWh';
    public static FLDdeltT = 'Delta T';
    public static FLDelecSave = 'Electricity saving kWh';
    public static FLDflowrate = 'Flowrate l/s';
    public static FLDoptFlowrate = 'OPtimal Flowrate l/s';
    public static FLDcoolLoad = 'cooling load kW';
    public static FLDcoolLoadCalc = 'cooling load kW (m*cp*dT)';
    public static FLDloadPerc = 'load percentage';
    public static FLDambTemp = 'Ambient temperature';

    public static CastError = 'Error';
    public static CastErrorNext = 'Error Next';

    public static CastNavigate = 'Navigate';
    public static CastGetChillers = 'GetChillers';
    public static CastGetChillerData = 'GetChillerData';
    public static CastGet3dData = 'Get3dData';
    public static CastLoggedIn = 'LoggedIn';
    public static CastLoggedOut = 'LoggedOut';
}
