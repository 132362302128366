<main>
    <nav class="uk-navbar-container" uk-navbar>
        <div class="uk-navbar-left">
            <div class="uk-navbar-item uk-logo uk-margin-left">
                <img src="/assets/logo.png" height="80" />
            </div>
        </div>
        <div class="uk-navbar-right uk-margin-right">
            <ul class="uk-navbar-nav">
                <li class="uk-margin-small-right">
                    <button class="uk-button uk-button-default" 
                        (click)="logout()"
                        type="button">Logout</button>
                </li>
                <li class="uk-margin-small-right">
                    <button class="uk-button uk-button-primary" 
                        (click)="api.login()"
                        type="button">Login with Google</button>
                </li>
            </ul>
        </div>
    </nav>

    <div class="uk-container-expand">
        
        <div *ngIf="displayPostError" class="uk-card uk-card-default gc-message">
            <div class="uk-card-body">
                <h2>There was an error communicating with the cloud API</h2>
                <p>Try clearing cache and logging in again</p>
            </div>   
        </div> 

    </div>
</main>