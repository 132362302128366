import { Component, OnInit } from '@angular/core';


import { Config } from './config/config';
import { ApiService } from './service/api.service';
import { IBroadcastMessage } from './model/core/broadcast-message.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'veolia';
  
  constructor(
      public api: ApiService) {
    }
    ngOnInit() {
    }
}
