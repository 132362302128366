import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { ServiceModule } from './service/service.module';

import { ApiService } from './service/api.service';

import { AppComponent } from './app.component';

import { LoginComponent } from './views/auth/login.component';
import { ChartsComponent } from './views/chart/charts.component';

import { CookieService } from 'ngx-cookie-service';

import { environment } from '../environments/environment';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    ChartsComponent
  ],
  providers: [ApiService, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }



