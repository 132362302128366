<main>
    <nav class="uk-navbar-container" uk-navbar>
        <div class="uk-navbar-left">
            <div class="uk-navbar-item uk-logo uk-margin-left">
                <img src="/assets/logo.png" height="80" />
            </div>
        </div>
        <div class="uk-navbar-right uk-margin-right">
            <ul class="uk-navbar-nav">
                <li class="uk-margin-small-right">
                    <button class="uk-button" 
                        (click)="toggleChartView()"
                        [class.uk-button-default]="displayTab=='3d'" 
                        [class.uk-button-primary]="displayTab=='general'"
                        type="button">Operational and COP Charts</button>
                </li>
                <li class="uk-margin-small-right">
                    <button class="uk-button" 
                        (click)="toggleChartView()"
                        [class.uk-button-default]="displayTab=='general'" 
                        [class.uk-button-primary]="displayTab=='3d'"
                        type="button">Manufacturer COP data</button>
                </li>
                <li>
                    <button class="uk-button uk-button-default" 
                        (click)="logout()"
                        type="button">Logout</button>
                </li>
            </ul>
        </div>
    </nav>

    <div class="uk-container-expand" [class.uk-hidden]="displayTab=='3d'">
        <form *ngIf="!displayPostError">
            <fieldset class="uk-fieldset">
                <div class="uk-card uk-card-default">
                    <div class="uk-card-header">
                        <div class="uk-flex uk-flex-middle uk-flex-between">
                            <h3 class="uk-text-bold uk-margin-remove">Chiller Variables</h3>
                            <div>&nbsp;</div>
                            <button *ngIf="displayCharts" type="button" (click)="toggleDataVariables()" class="uk-button uk-button-primary uk-button-small">
                                <span *ngIf="displayVariables" uk-icon="icon: triangle-up"></span>
                                <span *ngIf="!displayVariables" uk-icon="icon: triangle-down"></span>
                            </button>
                        </div>
                    </div>
                    <div class="uk-card-body" *ngIf="displayVariables">
                        <div class="uk-margin" *ngIf="displayVariables">
                            <label class="uk-form-label" for="form-stacked-select">
                                <span class="uk-text-bold">Date Range</span>
                            </label>
                            <div class="uk-form-controls uk-flex uk-flex-middle gc-chart-vars">
                                <div>from</div>
                                <input [value]="dateFrom | date:'yyyy-MM-dd'" class="uk-input uk-form-width-medium uk-form-small gc-date"
                                    (change)="setFromDate($event)" type="date">
                                <div>to</div>
                                <input [value]="dateTo | date:'yyyy-MM-dd'" class="uk-input uk-form-width-medium uk-form-small gc-date"
                                    (change)="setToDate($event)" type="date">
                            </div>
                        </div>

                        <div class="uk-margin" *ngIf="displayVariables && !displayLoading">
                            <label class="uk-form-label uk-text-bold" for="chillerSelect">Select Chiller(s)</label>
                            <div class="uk-form-controls">
                                <ng-template ngFor let-chiller [ngForOf]="chillers">
                                    <div class="uk-flex uk-flex-middle gc-chart-vars">  
                                        <div>
                                            <label><input [id]="'chiller-' + chiller.id" class="uk-checkbox" type="checkbox" 
                                                [checked]="chiller.selected" (change)="toggleChiller(chiller)">&nbsp;{{chiller.name}}</label>
                                        </div>
                                        <div [class.uk-invisible]="!chiller.selected">
                                            <label for="form-stacked-select">COP annual drop [0 to 1]</label>
                                            <div class="uk-form-controls">
                                                <input [id]="'chiller-' + chiller.id + '-drop'" [value]="chiller.dropOffPercent" class="uk-input uk-form-width-small uk-text-center uk-form-small"
                                                    (mouseup)="setDropPercent(chiller, $event)" (keyup)="setDropPercent(chiller, $event)"
                                                    type="number" step="0.01" min="0.01">
                                            </div>
                                        </div>
                                        <div [class.uk-invisible]="!chiller.selected">
                                            <label for="form-stacked-select">Years of Operation</label>
                                            <div class="uk-form-controls">
                                                <input [id]="'chiller-' + chiller.id + '-years'" [value]="chiller.operationYears" class="uk-input uk-form-width-xsmall uk-text-center uk-form-small" 
                                                    (mouseup)="setOperationYears(chiller, $event)" (keyup)="setOperationYears(chiller, $event)"
                                                    type="number" step="1" min="1">
                                            </div>
                                        </div> 
                                    </div>
                                </ng-template>
                            </div>
                        </div>     
                        <div class="uk-margin" *ngIf="displayVariables">
                            <button type="button" (click)="getChillerData()" class="uk-button uk-button-primary">Get Chiller Data &amp; Optimization Results</button>
                        </div>  
                    </div>
                    <div class="uk-card-footer uk-text-right" *ngIf="displayLink != ''">

                        <div class="uk-margin" *ngIf="displayLink != ''">
                            <a type="button" [href]="displayLink" target="new" class="uk-button" 
                                [class.uk-button-default]="!googleSheetIsCurrent"
                                [class.uk-button-primary]="googleSheetIsCurrent">Open Google Sheet</a>
                            <b [class.uk-hidden]="googleSheetIsCurrent">&nbsp;(not current with new chiller variables)</b>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>

        <div *ngIf="displayPostError" class="uk-card uk-card-default gc-message">
            <div class="uk-card-body">
                <h2>There was an error communicating with the cloud API</h2>
                <p>Try logging out, clearing cache and logging in again</p>
            </div>   
        </div>        

        <div *ngIf="displayLoading" class="uk-card uk-card-default gc-message">
            <div class="uk-card-body">
                <h2>Loading...</h2>
            </div>   
        </div>

        <div *ngIf="displayMissingData" class="uk-card uk-card-default gc-message">
            <div class="uk-card-body">
                <h2>No data found in the date range for units specified</h2>
            </div>   
        </div>

        <div *ngIf="displayApiError" class="uk-card uk-card-default gc-message">
            <div class="uk-card-body">
                <h2>Error communicating with Cloud API</h2>
            </div>   
        </div>

        <div *ngIf="displayCharts" class="uk-card uk-card-default gc-tabs">
            <div class="uk-card-body">
                <button type="button" (click)="toggleAnalysis()" 
                    [class.uk-button-default]="this.displayCooling" 
                    [class.uk-button-primary]="this.displayCOP"
                    class="uk-button">COP Analysis</button>                    
                <button type="button" (click)="toggleAnalysis()" 
                    [class.uk-button-default]="this.displayCOP" 
                    [class.uk-button-primary]="this.displayCooling"
                    class="uk-button">Cooling Load Analysis</button>
            </div>   
        </div>

        <div [class.uk-invisible]="!displayCharts">
            <div class="uk-card uk-card-default gc-container" [class.uk-hidden]="!displayCOP">
                <div class="uk-card-header uk-flex uk-flex-between">
                    <div class="uk-flex uk-flex-auto uk-margin-remove uk-flex-middle gc-selector">
                        <div *ngIf="chartViews[0] != null && displayChartSelectors">
                            <label>View Units: </label>&nbsp;
                            <button
                                (click)="toggleChartUnits(0, 0)"
                                [class.uk-button-default]="!chartViews[0][0]" 
                                [class.uk-button-primary]="chartViews[0][0]">All</button>&nbsp;
                            <ng-template ngFor let-chiller="$implicit" [ngForOf]="availableChillers()" let-i="index">
                                <button 
                                    (click)="toggleChartUnits(0, i+1)"
                                    [class.uk-button-default]="!chartViews[0][i+1]" 
                                    [class.uk-button-primary]="chartViews[0][i+1]">{{chiller.name}}</button>
                                    &nbsp;
                            </ng-template>
                        </div>
                    </div>
                    <div #chart1_thumb class="gc-thumbnail"></div>
                </div>
                <div #chart1_div class="uk-card-body"></div>
            </div>

            <div class="uk-card uk-card-default gc-container" [class.uk-hidden]="!displayCOP">
                <div class="uk-card-header uk-flex uk-flex-between">
                    <div class="uk-flex uk-flex-auto uk-margin-remove uk-flex-middle gc-selector" >
                        <div *ngIf="chartViews[0] != null && displayChartSelectors">
                            <label>View Units: </label>&nbsp;
                            <button
                                (click)="toggleChartUnits(1, 0)"
                                [class.uk-button-default]="!chartViews[1][0]" 
                                [class.uk-button-primary]="chartViews[1][0]">All</button>&nbsp;
                            <ng-template ngFor let-chiller="$implicit" [ngForOf]="availableChillers()" let-i="index">
                                <button 
                                    (click)="toggleChartUnits(1, i+1)"
                                    [class.uk-button-default]="!chartViews[1][i+1]" 
                                    [class.uk-button-primary]="chartViews[1][i+1]">{{chiller.name}}</button>
                                    &nbsp;
                            </ng-template>
                        </div>
                    </div>
                    <div #chart2_thumb class="gc-thumbnail"></div>
                </div>
                <div class="uk-card-body">
                    <div #chart2_div></div>
                </div>
            </div>

            <div class="uk-card uk-card-default gc-container" [class.uk-hidden]="!displayCOP">
                <div class="uk-card-header uk-flex uk-flex-between">
                    <div class="uk-flex uk-flex-auto uk-margin-remove uk-flex-middle gc-selector">
                        <div *ngIf="chartViews[0] != null && displayChartSelectors">
                            <label>View Units: </label>&nbsp;
                            <button
                                (click)="toggleChartUnits(2, 0)"
                                [class.uk-button-default]="!chartViews[2][0]" 
                                [class.uk-button-primary]="chartViews[2][0]">All</button>&nbsp;
                            <ng-template ngFor let-chiller="$implicit" [ngForOf]="availableChillers()" let-i="index">
                                <button 
                                    (click)="toggleChartUnits(2, i+1)"
                                    [class.uk-button-default]="!chartViews[2][i+1]" 
                                    [class.uk-button-primary]="chartViews[2][i+1]">{{chiller.name}}</button>
                                    &nbsp;
                            </ng-template>
                        </div>
                    </div>
                    <div #chart3_thumb class="gc-thumbnail"></div>
                </div>
                <div class="uk-card-body">
                    <div #chart3_div></div>
                </div>
            </div>

            <div class="uk-card uk-card-default gc-container" [class.uk-hidden]="!displayCooling">
                <div class="uk-card-header uk-flex uk-flex-between">
                    <div class="uk-flex uk-flex-auto uk-margin-remove uk-flex-middle gc-selector">
                        <div *ngIf="chartViews[0] != null && displayChartSelectors" class="gc-units">
                            <label>View Units: </label>&nbsp;
                            <button
                                (click)="toggleChartUnits(3, 0)"
                                [class.uk-button-default]="!chartViews[3][0]" 
                                [class.uk-button-primary]="chartViews[3][0]">All</button>&nbsp;
                            <ng-template ngFor let-chiller="$implicit" [ngForOf]="availableChillers()" let-i="index">
                                <button 
                                    (click)="toggleChartUnits(3, i+1)"
                                    [class.uk-button-default]="!chartViews[3][i+1]" 
                                    [class.uk-button-primary]="chartViews[3][i+1]">{{chiller.name}}</button>
                                    &nbsp;
                            </ng-template>
                        </div>
                        <button class="uk-button-primary gc-reset-button" (click)="drawChart4()">Reset Dates</button>
                    </div>
                    <div #chart4_thumb class="gc-thumbnail"></div>
                </div>
                <div class="uk-card-body">
                    <div #chart4_div></div>
                </div>
            </div>

            <div class="uk-card uk-card-default gc-container" [class.uk-hidden]="!displayCooling">
                <div class="uk-card-header uk-flex uk-flex-between">
                    <div class="uk-flex uk-flex-auto uk-margin-remove uk-flex-middle gc-selector">
                        <div *ngIf="chartViews[0] != null && displayChartSelectors">
                            <label>View Units: </label>&nbsp;
                            <button
                                (click)="toggleChartUnits(4, 0)"
                                [class.uk-button-default]="!chartViews[4][0]" 
                                [class.uk-button-primary]="chartViews[4][0]">All</button>&nbsp;
                            <ng-template ngFor let-chiller="$implicit" [ngForOf]="availableChillers()" let-i="index">
                                <button 
                                    (click)="toggleChartUnits(4, i+1)"
                                    [class.uk-button-default]="!chartViews[4][i+1]" 
                                    [class.uk-button-primary]="chartViews[4][i+1]">{{chiller.name}}</button>
                                    &nbsp;
                            </ng-template>
                        </div>
                    </div>
                    <div #chart5_thumb class="gc-thumbnail"></div>
                </div>
                <div class="uk-card-body">
                    <div #chart5_div></div>
                </div>
            </div>

            <div class="uk-card uk-card-default gc-container" [class.uk-hidden]="!displayCooling">
                <div class="uk-card-header uk-flex uk-flex-between">
                    <div class="uk-flex uk-flex-auto uk-margin-remove uk-flex-middle gc-selector">
                        <div *ngIf="chartViews[0] != null && displayChartSelectors">
                            <label>View Units: </label>&nbsp;
                            <button
                                (click)="toggleChartUnits(5, 0)"
                                [class.uk-button-default]="!chartViews[5][0]" 
                                [class.uk-button-primary]="chartViews[5][0]">All</button>&nbsp;
                            <ng-template ngFor let-chiller="$implicit" [ngForOf]="availableChillers()" let-i="index">
                                <button 
                                    (click)="toggleChartUnits(5, i+1)"
                                    [class.uk-button-default]="!chartViews[5][i+1]" 
                                    [class.uk-button-primary]="chartViews[5][i+1]">{{chiller.name}}</button>
                                    &nbsp;
                            </ng-template>
                        </div>
                    </div>
                    <div #chart6_thumb class="gc-thumbnail"></div>
                </div>
                <div class="uk-card-body">
                    <div #chart6_div></div>
                </div>
            </div>

        </div>

        
        <div>&nbsp;</div>
    </div>
            
    <div class="uk-container-expand" [class.uk-hidden]="displayTab=='general'">
        <div class="uk-card uk-card-default">
            <div *ngIf="!displayPostError" class="uk-card-body">
                <label class="uk-form-label uk-text-bold" for="chillerSelect">Select Chiller</label>
                <div class="uk-form-controls">
                    <select class="uk-select uk-form-width-small"
                        (change)="loadChiller3d($event)">
                        <option value="0">Select Chiller</option>
                        <ng-template ngFor let-chiller [ngForOf]="chillers">
                            <option class="uk-button" [value]="chiller.name">{{chiller.name}}</option>
                        </ng-template>
                    </select>
                </div>
            </div>   
        </div>

        <div *ngIf="displayPostError" class="uk-card uk-card-default gc-message">
            <div class="uk-card-body">
                <h2>There was an error communicating with the cloud API</h2>
                <p>Try logging out, clearing cache and logging in again</p>
            </div>   
        </div>

        <div *ngIf="displayLoading" class="uk-card uk-card-default gc-message">
            <div class="uk-card-body uk-text-center">
                <h2>Loading...</h2>
            </div>   
        </div>

        <div class="uk-card uk-card-default uk-card-body uk-width-1-1 gc-container uk-margin-remove-bottom uk-padding-remove-bottom" [class.uk-invisible]="!display3DChart">
            <div class="uk-margin-remove gc-selector">
                <div class="uk-flex uk-flex-center">Chilled Water Supply Temperature (CWST)(C°): </div>
                <div class="uk-flex uk-margin-remove uk-flex-center uk-flex-middle gc-selector">
                    <ng-template ngFor let-evap="$implicit" [ngForOf]="evaps3d" let-i="index">
                        <button 
                            (click)="selectEvapTemp(evap)"
                            [class.uk-button-default]="selectedEvap!=evap" 
                            [class.uk-button-primary]="selectedEvap==evap">{{evap}}C°</button>
                            &nbsp;
                    </ng-template>
                </div>
            </div>
            <div #chart7_div></div>
        </div>
        
        <div>&nbsp;</div>
    </div>
</main>